<template>
  <div id="app">
    <index msg=""/>
  </div>
</template>

<script>
import index from './components/index.vue'

export default {
  name: 'App',
  components: {
    index
  }
}
</script>

<style>
html,body{
  margin: 0;
  padding: 0;
}

#app {

}
</style>
