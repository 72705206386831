<template>
  <div class="main">
    <div class="content">
      <div class="header">

        <div class="headerbg">
          <div class="headertree">
            <div class="headerbox">
              <div class="lineone"></div>
              <div class="wordone">童</div>
              <div class="wordtow">趣</div>
              <div class="wordthree">时</div>
              <div class="wordfour">刻</div>
              <div class="linetow"></div>
              <div class="heat"></div>
            </div>
            <div class="titlebox">
              <div class="titlebg">
                <div class="title">高端公仔 萌物上新</div>
              </div>
            </div>
            <div class="shipbox">
              <div class="shipbg">
                <div class="shipicon"></div>
              </div>

            </div>
            <div class="lotusbox">
              <div class="lotus"></div>
            </div>
          </div>
        </div>
        <div class="video">
          <video id="video"
                 muted="muted"
                 loop="loop"
                 playsinline=""
                 preload="auto"
                 mtt-playsinline="true"
                 webkit-playsinline="true"
                 x-webkit-airplay="true"
                 x5-video-player-fullscreen="true"
                 x5-video-player-type="h5"
                 src="../assets/video.mp4"
                 poster=""
                 autoplay="autoplay" class="editor-video-player__content"
                 style=""></video>
        </div>
      </div>
      <div class="allbg">
        <div class="boxwapper">
          <div class="boxone"></div>
          <div class="boxtow"></div>
          <div class="boxthree"></div>
        </div>
        <div class="jingpin">
          <div class="jingbox">
            <div class="jingtitle">精品推荐</div>
          </div>
        </div>
        <div class="jingwapper">
          <div class="pinbox">
            <div class="whilebox">
              <div class="productborder">
                <div class="product">
                  <div class="producttitle">
                    <div class="titles">坐姿屹耳公仔</div>
                    <div class="prices">79.9元</div>
                  </div>
                  <div class="productimg">
                    <img src="../assets/800x800aa.jpg">
                  </div>
                </div>
              </div>
              <div class="productborder">
                <div class="product">
                  <div class="producttitle">
                    <div class="titles">坐姿小熊维尼公仔</div>
                    <div class="prices">79.9元</div>
                  </div>
                  <div class="productimg">
                    <img src="../assets/800x800cc.jpg">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="allbg">
        <div class="towbox">
          <div class="towkuai"></div>
          <div class="towkuaitow"></div>
          <div class="towkuaithree"></div>
        </div>
        <div class="productwapper">
          <div class="productbg">
            <div class="productheader">
              <div class="headerlineone"></div>
              <div class="headertitle">
                <div class="htitles">
                  <p>宝宝的礼物</p>
                  <p>邂逅最美的妈妈</p>
                </div>
                <div class="heartone"></div>
                <div class="hearttow"></div>
              </div>
              <div class="headerlinetow"></div>
            </div>
            <div class="productlist">
              <div class="peoductbox">
                <div class="boxleft">
                  <div class="boxpro">
                    <img src="../assets/800x800-0.png">
                  </div>
                  <div class="probottom"></div>
                </div>
                <div class="productright">
                  <div class="productname">坐姿史迪奇公仔</div>
                  <div class="productbotton">
                    <div class="productprice">
                      <div class="leftprice">活动价:</div>
                      <div class="rightprice">
                        <div class="unit">￥</div>
                        <div class="money">69.9</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="peoductbox">
                <div class="productright active">
                  <div class="productname">坐姿跳跳虎公仔</div>
                  <div class="productbotton">
                    <div class="productprice">
                      <div class="leftprice">活动价:</div>
                      <div class="rightprice">
                        <div class="unit">￥</div>
                        <div class="money">79.9</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="boxleft">
                  <div class="boxpro">
                    <img src="../assets/800x800A.png">
                  </div>
                  <div class="probottom"></div>
                </div>
              </div>
            </div>
            <div class="productfooter"></div>
          </div>
        </div>
      </div>
      <div class="allbg">
        <div class="productwapper">
          <div class="productbg active">
            <div class="productheader">
              <div class="headerlineone"></div>
              <div class="headertitle">
                <div class="htitles">
                  <p>宝宝的成长</p>
                  <p>来自妈妈的爱</p>
                </div>
                <div class="heartone"></div>
                <div class="hearttow"></div>
              </div>
              <div class="headerlinetow"></div>
            </div>
            <div class="productlist2">
              <div class="productbox2">
                <div class="productimg2">
                  <img src="../assets/800X800-6.png">
                </div>
                <div class="productbottom"></div>
                <div class="productname2">经典版海绵宝宝</div>
                <div class="productbotton">
                  <div class="productprice">
                    <div class="leftprice">活动价:</div>
                    <div class="rightprice">
                      <div class="unit">￥</div>
                      <div class="money">69.9</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="productbox2">
                <div class="productimg2">
                  <img src="../assets/800X800-5.png">
                </div>
                <div class="productbottom"></div>
                <div class="productname2">Q版蟹老板</div>
                <div class="productbotton">
                  <div class="productprice">
                    <div class="leftprice">活动价:</div>
                    <div class="rightprice">
                      <div class="unit">￥</div>
                      <div class="money">69.9</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="productbox2">
                <div class="productimg2">
                  <img src="../assets/800X800-8.png">
                </div>
                <div class="productbottom"></div>
                <div class="productname2">经典版小蜗</div>
                <div class="productbotton">
                  <div class="productprice">
                    <div class="leftprice">活动价:</div>
                    <div class="rightprice">
                      <div class="unit">￥</div>
                      <div class="money">69.9</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="productbox2">
                <div class="productimg2">
                  <img src="../assets/800X800-4.png">
                </div>
                <div class="productbottom"></div>
                <div class="productname2">经典版章鱼哥</div>
                <div class="productbotton">
                  <div class="productprice">
                    <div class="leftprice">活动价:</div>
                    <div class="rightprice">
                      <div class="unit">￥</div>
                      <div class="money">69.9</div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="productfooter"></div>
          </div>
        </div>
      </div>
      <div class="threewapper">
        <div class="headerlineone"></div>
        <div class="headertitle">
          <div class="htitles">
            <p>新品上市</p>
            <p>安全无害质量保障</p>
          </div>
          <div class="heartone"></div>
          <div class="hearttow"></div>
        </div>
        <div class="headerlinetow"></div>
        <div class="kuais"></div>
      </div>
      <div class="productlist3">
        <div class="alllist">
          <div class="boxonebg">
            <div class="whilebg">
              <div class="whileflex">
                <div class="producrimg3">
                  <img src="../assets/800x8003.jpg">
                </div>
              </div>
              <div class="whileflex">
                <div class="producrimg3">
                  <img src="../assets/800x8008.jpg">
                </div>
              </div>
            </div>
          </div>
          <div class="allbutton">
            <div class="allflex">
              <div class="productbotton">
                <div class="productprice">
                  <div class="leftprice">活动价:</div>
                  <div class="rightprice">
                    <div class="unit">￥</div>
                    <div class="money">29.9</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="allflex">
              <div class="productbotton">
                <div class="productprice">
                  <div class="leftprice">活动价:</div>
                  <div class="rightprice">
                    <div class="unit">￥</div>
                    <div class="money">29.9</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="alllist">
          <div class="boxonebg">
            <div class="whilebg">
              <div class="whileflex">
                <div class="producrimg3">
                  <img src="../assets/800x8009.jpg">
                </div>
              </div>
              <div class="whileflex">
                <div class="producrimg3">
                  <img src="../assets/800x80011.jpg">
                </div>
              </div>
            </div>
          </div>
          <div class="allbutton">
            <div class="allflex">
              <div class="productbotton">
                <div class="productprice">
                  <div class="leftprice">活动价:</div>
                  <div class="rightprice">
                    <div class="unit">￥</div>
                    <div class="money">29.9</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="allflex">
              <div class="productbotton">
                <div class="productprice">
                  <div class="leftprice">活动价:</div>
                  <div class="rightprice">
                    <div class="unit">￥</div>
                    <div class="money">29.9</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="footerone"></div>
        <div class="footertow"></div>
        <div class="footerthree"></div>
        <div class="footercode">
          <img src="../assets/icon.jpg">
        </div>
        <div class="footerfour"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "index.vue",
  data() {
    return {

    }
  },
  created(){
    var video = document.getElementById('video');
    document.attachEvent("WeixinJSBridgeReady", function () {
      setTimeout(function (){
        video.play();
      },2000)

    }, false);
  },
}
</script>

<style scoped>
.main {
  margin: 0;
  padding: 0;
}

.content {
  background-image: url("../assets/20200110-181731-a322.jpeg");
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: top center;
  position: relative;
  overflow: hidden;
}

.header {
  position: relative;
  height: 36rem;
  background-image: url("../assets/20200110-181816-d881.jpeg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: top center;
}

.headerbg {
  left: 0;
  right: 0;
  top: 0;
  position: absolute;
  height: 25rem;
  background-image: url("../assets/20200110-181819-a0d9.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: top center;
}

.headertree {
  left: 0;
  right: 0;
  top: 0;
  position: absolute;
  height: 20rem;
  background-image: url("../assets/20200110-181816-aea8.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: top center;
}

.headerbox {
  display: flex;
  justify-content: center;
  position: relative;
  left: 0;
  right: 0;
  top: 0;
}

.lineone {
  width: 1rem;
  height: .35rem;
  background-image: url("../assets/20200110-181816-f3fe.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  position: relative;
  top: 3rem;
}

.wordone {
  font-size: 2rem;
  color: #ffffff;
  margin-top: .25rem;
  margin-left: .5rem;
  font-weight: bolder;
  position: relative;
  top: 3.5rem;
}

.wordtow {
  margin-left: .5rem;
  font-size: 2.5rem;
  margin-top: .5rem;
  color: rgb(255, 238, 77);
  font-weight: bolder;
  position: relative;
  top: 3.9rem;
}

.wordthree {
  margin-left: .5rem;
  font-size: 1.8rem;
  color: white;
  margin-top: .5rem;
  font-weight: bolder;
  position: relative;
  top: 2.8rem;
}

.wordfour {
  margin-left: .5rem;
  font-size: 2.8rem;
  margin-top: .5rem;
  color: white;
  font-weight: bolder;
  position: relative;
  top: 2.7rem;
}

.linetow {
  width: 1rem;
  height: .35rem;
  background-image: url("../assets/20200110-181816-7591.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  position: relative;
  left: -1rem;
  top: 3.35rem;
}

.titlebox {
  display: flex;
  justify-content: center;
  position: relative;
  top: 4rem;
}

.titlebg {
  width: 14rem;
  height: 1.5rem;
  background-image: url("../assets/20200110-181816-bb3b.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
}

.title {
  color: rgb(7, 36, 75);
  font-size: .8rem;
  text-align: center;
  line-height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: .5rem;
}

.shipbox {
  /*display: flex;*/
  /*justify-content: center;*/
  /*align-items: center;*/
  position: relative;
}

.shipbg {
  height: 20rem;
  background-image: url("../assets/20200110-181816-0459.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: top center;
  position: absolute;
  left: 2rem;
  right: -9rem;
  top: 7rem;
  z-index: 1;
}

.shipicon {
  height: 18rem;
  width: 17rem;
  background-image: url("../assets/bae.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  position: absolute;
  left: 3rem;
  top: -1.8rem;
}

.lotusbox {
  position: relative;
  top: 14rem;
  z-index: 10;
}

.lotus {
  height: 14rem;
  background-image: url("../assets/20200110-181816-f4e3.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: top center;
}

.heat {
  width: 2rem;
  height: 1.7rem;
  background-image: url("../assets/20200110-181816-3a72.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  left: 9rem;
  top: 3rem;
  background-position: top center;
}

.boxwapper {
  position: relative;
}

.boxone {
  width: 2rem;
  height: 3rem;
  background-image: url("../assets/20200110-181816-7373.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;
  left: -.5rem;
  top: 0rem;
  z-index: 999;
  background-position: top center;
}

.boxtow {
  width: 4rem;
  height: 6rem;
  background-image: url("../assets/20200110-181816-b383.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;
  left: 5rem;
  top: -6rem;
  z-index: 999;
  background-position: top center;
}

.boxthree {
  width: 12rem;
  height: 6rem;
  background-image: url("../assets/20200110-181816-96a6.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  right: 0rem;
  top: -2rem;
  z-index: 999;
  background-position: top center;
}

.jingpin {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -5rem;
}

.jingbox {
  width: 8rem;
  height: 1.5rem;
  background-image: url("../assets/20200110-181816-aa24.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: top center;
}

.jingtitle {
  color: rgb(7, 36, 75);
  font-size: .8rem;
  text-align: center;
  line-height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: .5rem;
}

.jingwapper {
  padding: 0 .5rem;
  margin-top: -.75rem;
}

.pinbox {
  background-image: url("../assets/20200110-181816-2e07.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: top center;
  padding: 1rem 1.5rem;
}

.whilebox {
  height: 5rem;
  background-image: url("../assets/20200110-181816-8ef4.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: top center;
  padding: .5rem 1rem 1.2rem .5rem;
  display: flex;
}

.allbg {
  /*background-image: url("../assets/20200110-181816-0dcd.jpeg");*/
  /*background-repeat: no-repeat;*/
  /*background-size: 100% 100%;*/
  /*background-position: top center;*/
}

.productborder {
  flex: 1;
  padding: .5rem 0;
}

.product {
  padding: 0 .5rem;
  display: flex;
  align-items: center;
  border-right: .025rem solid rgb(7, 36, 75);
}

.whilebox .productborder:last-child .product {
  border-right: none;
}

.producttitle {
  flex: 1;

}

.titles {
  color: rgb(12, 41, 72);
  font-size: .69rem;

}

.prices {
  height: 1rem;
  background-image: url("../assets/20200110-181816-83be.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: top center;
  margin-top: .5rem;
  color: rgb(18, 46, 77);
  font-size: .5rem;
  text-align: center;
}

.productimg {
  margin-left: 1rem;
  width: 4.2rem;
  height: 4rem;
}

.productimg img {
  width: 100%;
  /*height: 100%;*/
}

.towbox {
  position: relative;
  display: flex;
  justify-content: flex-end;
  padding-top: 3rem;
}

.towkuai {
  width: 2rem;
  height: 3rem;
  background-image: url(../assets/20200110-181816-7373.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;
  right: 5rem;
  top: 0rem;
  z-index: 999;
  background-position: top center;
}

.towkuaitow {
  width: 3rem;
  height: 5rem;
  background-image: url(../assets/20200110-181816-7373.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;
  right: 3rem;
  top: -3rem;
  z-index: 999;
  background-position: top center;
}

.towkuaithree {
  width: 12rem;
  height: 6rem;
  background-image: url(../assets/20200110-181816-96a6.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  right: -8rem;
  top: 1rem;
  z-index: 999;
  background-position: top center;
}

.productwapper {
  padding: 0 .5rem;
}

.productwapper.active {
  padding-top: 3rem;
}
.produceheader{

}
.productbg {


  margin-top: -3rem;
}

.productbg.active {
  margin-top: 0;
}

.productheader {
  padding: 6rem 0 0 0;
  background-image: url(../assets/20200110-181816-eea5-1.png);
  background-repeat: no-repeat;
  background-size: 135% 100%;
  background-position: top center;
  display: flex;
  align-items: center;
  justify-content: center;
  /*padding-top: 4rem;*/
}
.productfooter{
  background-image: url(../assets/20200110-181816-eea5-3.png);
  background-repeat: no-repeat;
  background-size: 135% 100%;
  background-position: top center;
  height: 3rem;
}
.headerlineone {
  width: 3rem;
  height: .8rem;
  background-image: url(../assets/20200110-181816-0f53.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: top center;
}

.headertitle {
  position: relative;
}

.htitles {
  color: rgb(255, 255, 255);
  font-size: .6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.htitles p {
  margin: 0;
  padding: 0;
}

.headerlinetow {
  width: 3rem;
  height: .8rem;
  background-image: url(../assets/20200110-181816-70ee.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: top center;
}

.heartone {
  width: 1.2rem;
  height: 1rem;
  background-image: url(../assets/20200110-181816-6bc2.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: top center;
  position: absolute;
  top: -1rem;
}

.hearttow {
  width: 2.5rem;
  height: 2rem;
  background-image: url(../assets/20200110-181816-5e23.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: top center;
  position: absolute;
  top: -1rem;
  right: -2rem;
}

.productlist {
  padding-top: 3rem;
  background-image: url(../assets/20200110-181816-eea5-2.png);
  background-repeat: no-repeat;
  background-size: 135% 100%;
  background-position: top center;
}

.productlist2 {
  padding-top: 3rem;
  display: flex;
  flex-wrap: wrap;
  background-image: url(../assets/20200110-181816-eea5-2.png);
  background-repeat: no-repeat;
  background-size: 135% 100%;
  background-position: top center;
}

.peoductbox {
  padding: 0 1rem;
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
}

.productlist .peoductbox:last-child {
  margin-bottom: 0;
}

.boxleft {
  width: 11rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.boxpro {
  width: 8rem;
  height: 7.8rem;
  position: relative;
  z-index: 100;
}

.boxpro img {
  width: 100%;
  /*height: 100%;*/
}

.probottom {
  width: 11rem;
  height: 6rem;
  background-image: url(../assets/20200110-181816-f853.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: top center;
  position: relative;
  margin-top: -3rem;
}

.productright {
  margin-left: .5rem;
  flex: 1;
}

.productright.active {
  margin-left: 0;
  margin-right: .5rem;
}

.productname {
  color: rgb(238, 182, 123);
  font-size: .7rem;
}

.productbotton {
  margin-top: 1rem;
  height: 7rem;
  background-image: url(../assets/20200110-181816-9cdd.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: top center;
  margin-left: -1rem;
  width: 8rem;
}

.productprice {
  display: flex;
  align-items: baseline;
  padding-top: .7rem;
  padding-left: 1.2rem;
}

.leftprice {
  color: rgb(56, 39, 28);
  font-size: .55rem;
}

.rightprice {
  flex: 1;
  display: flex;
  align-items: baseline;

}

.unit {
  color: rgb(90, 53, 28);
  font-size: .55rem;
}

.money {
  color: rgb(90, 53, 28);
  font-size: 1rem;
}
.productbox2{
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.productimg2{
  width: 6rem;
  height: 8rem;
  position: relative;
  z-index: 100;
  left: -1.8rem;
}
.productimg2 img{
  /*width: 100%;*/
  height: 100%;
}
.productbottom{
  width: 9rem;
  height: 5rem;
  background-image: url(../assets/20200110-181816-7271.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: top center;
  position: relative;
  top: -2.5rem;
}
.productname2{
  font-size: .7rem;
  margin-top: -2rem;
  color: rgb(238, 182, 123);
}
.threewapper{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3rem;
  position: relative;
}
.kuais{
  width: 4rem;
  height: 6rem;
  background-image: url(../assets/20200110-181816-b383.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: top center;
  position: absolute;
  top: 0rem;
  left: 1rem;
}
.productlist3{
  padding: 0 .5rem;
  margin-top: 3rem;
  position: relative;

}
.boxonebg{
  background-image: url(../assets/20200110-181819-68a2.png);
  background-repeat: no-repeat;
  background-size: 135% 150%;
  background-position: center center;
  padding: .5rem;

}
.whilebg{
  display: flex;
  align-items: center;

}
.whileflex{
  background-color: white;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: .5rem;
}
.whilebg .whileflex:last-child{
  margin-right: 0;
}
.producrimg3{
  width: 10rem;
  height: 7rem;
  display: block;
  text-align: center;
}
.producrimg3 img{
  height: 100%;
}
.alllist{

}
.allbutton{
  margin-top: 1rem;
  display: flex;
  align-items: center;
}
.allflex{
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: .5rem;
  margin-top: -1rem;
}
.allbutton .allflex:last-child{
  margin-right: 0;
}
.productlist3 .alllist:nth-of-type(n+2){
  margin-top: -2rem;
}
.footer{
  position: relative;
  margin-top: 3rem;
  display: flex;
  justify-content: center;
  padding-bottom: 8rem;
}
.footerone{
  height: 3rem;
  background-image: url(../assets/20200110-181816-123b.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center center;
  width: 13rem;
}
.footertow{
  width: 4rem;
  height: 6rem;
  background-image: url(../assets/20200110-181816-b383.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center center;
  position: absolute;
  top: -5rem;
}
.footerthree{
  width: 4.5rem;
  height: 4rem;
  background-image: url(../assets/20200110-181816-5e23.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center center;
  position: absolute;
  right: 5rem;
  top: -4rem;
}
.footerfour{
  width: 12rem;
  height: 6rem;
  background-image: url(../assets/20200110-181816-96a6.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center center;
  position: absolute;
  top: 4rem;
  margin-left: 2rem;
}
.footercode{
  width: 5rem;
  height: 5rem;
  position: absolute;
  top: 2rem;
  z-index: 100;
}
.footercode img{
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.video{
  position: absolute;
  left: 0;
  /*bottom: 0;*/
  right: 0;
  top: 0;
}
video {
  mix-blend-mode: screen;
  width: 100%;
  height: 100%;
}
</style>